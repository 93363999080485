import React, { useState } from 'react';
import { ChakraProvider, Box, FormControl, FormLabel, Input, Textarea, Button, VStack, Heading, Image, useToast, Text } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { storage } from './firebaseConfig';
import axios from 'axios';

// Optionally extend the theme to customize Chakra UI's default styles
const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: 'gray.100',
                color: 'black',
            },
        },
    },
});

function PlayerForm() {
    const [file, setFile] = useState(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        teamName: '',
        number: '',
        position: '',
        gradYear: '',
        highSchoolYear: '',
        description: ''
    });
    const toast = useToast();



    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleChange = (event) => {
        const { id, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!file) {
            toast({
                title: "No file selected.",
                description: "Please select a file to upload.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });

            return;
        }

        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on('state_changed',
            (snapshot) => {
                // Progress function ...
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            },
            (error) => {
                // Error function ...
                console.error(error);
                toast({
                    title: "Upload failed.",
                    description: `There was an error uploading your file: ${error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            },
            () => {
                // Complete function ...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    toast({
                        title: "File uploaded.",
                        description: "Your file has been uploaded successfully.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });

                    // Send form data to server
                    axios.post('https://us-central1-big-fire-player-portal.cloudfunctions.net/api/submit', { // Update this URL
                        ...formData,
                        fileName: file.name
                    })
                        .then(async (response) => {
                            console.log(response.data.message);
                        })
                        .catch((error) => {
                            console.error('Error submitting form data:', error);
                        });
                });
                // Delay the page reload by 3 seconds (3000 milliseconds)
                setTimeout(() => {
                    window.location.reload();
                }, 5000); // Adjust the delay as needed

            }
        );
    };

    return (
        <ChakraProvider theme={theme}>
            <Box p={4} maxW="md" mx="auto" mt={5} bg="white" boxShadow="md" borderRadius="md">
                <Box textAlign="center" mb={4}>
                    <Image src="/Big-fire.png" alt="Big Fire Show Logo" mx="auto" mb={4} boxSize="150px" objectFit="contain" />
                    <Heading as="h1" size="xl" color="black">Big Fire Player Portal</Heading>
                </Box>

                <Text fontSize="md" color="grey.600" textAlign="center" marginBottom="20px">
                    Please try not to be behind the net or backstop and to follow the player throughout the play.
                </Text>
                <form onSubmit={handleSubmit}>
                    <VStack spacing={4}>
                        <FormControl id="firstName" isRequired>
                            <FormLabel>First Name</FormLabel>
                            <Input placeholder="Enter player first name" value={formData.firstName} onChange={handleChange} />
                        </FormControl>

                        <FormControl id="lastName" isRequired>
                            <FormLabel>Last Name</FormLabel>
                            <Input placeholder="Enter player last name" value={formData.lastName} onChange={handleChange} />
                        </FormControl>

                        <FormControl id="teamName" isRequired>
                            <FormLabel>Team Name</FormLabel>
                            <Input placeholder="Enter player team name" value={formData.teamName} onChange={handleChange} />
                        </FormControl>

                        <FormControl id="number" isRequired>
                            <FormLabel>Number</FormLabel>
                            <Input placeholder="Enter player number" type="number" value={formData.number} onChange={handleChange} />
                        </FormControl>

                        <FormControl id="position" >
                            <FormLabel>Position</FormLabel>
                            <Input placeholder="Enter player position" value={formData.position} onChange={handleChange} />
                        </FormControl>

                        <FormControl id="gradYear" isRequired>
                            <FormLabel>Grad Year</FormLabel>
                            <Input placeholder="Enter player graduation year" type="number" value={formData.gradYear} onChange={handleChange} />
                        </FormControl>


                        <FormControl id="description" isRequired>
                            <FormLabel>Description</FormLabel>
                            <Textarea placeholder="Enter a description about the highlight" value={formData.description} onChange={handleChange} />
                        </FormControl>

                        <FormControl id="uploadVideo" isRequired >
                            <FormLabel>Upload Video</FormLabel>
                            <Input type="file" accept="video/*" onChange={handleFileChange}
                                padding="1"
                                borderColor="gray.300"
                                _hover={{ borderColor: "gray.500" }}
                                _focus={{ borderColor: "black.500", boxShadow: "outline" }} />
                        </FormControl>

                        <Button colorScheme="red" type="submit">
                            Submit
                        </Button>
                    </VStack>
                </form>
            </Box>
        </ChakraProvider>
    );
}

export default PlayerForm;