import React from 'react';
import PlayerForm from './PlayerForm';

function App() {
  return (
    <div>
      <PlayerForm />
    </div>
  );
}

export default App;



